import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { BASE_URL } from "constants";

const FormDialog = ({ data, onClose,onOpen  }) => {
  const [open, setOpen] = useState(false);
  const [accStatus, setAccStatus] = useState(data.Status === "1");
  const [recordsUserPermission, setUserPermissionRecord] = useState([]);
  const [recordsPermission, setPermissionRecord] = useState([]);
  const [permissions, setPermissions] = useState({});

  const { empNo: Id, idUser: UMserId, username } = data;

  // Fetch user-specific permissions
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/auth/userPermitionAll/${UMserId}`);
        setUserPermissionRecord(res.data);
      } catch (err) {
        console.error("Error fetching user permissions:", err);
      }
    };
    fetchUserDetails();
  }, [UMserId]);

  // Fetch all available permissions
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/auth/allRoute`);
        setPermissionRecord(res.data);
      } catch (err) {
        console.error("Error fetching permissions:", err);
      }
    };
    fetchUserPermissions();
  }, []);

  // Open dialog and set permissions
  const handleClickOpen = () => {
    setOpen(true);
    if (onOpen) onOpen();
    const initialPermissions = {};
    recordsUserPermission.forEach((permission) => {
      initialPermissions[permission.routeName] = {
        View: permission.Viwe === "1",
        Add: permission.Add === "1",
        Edit: permission.Edit === "1",
        Delete: permission.Delete === "1",
      };
    });
    setPermissions(initialPermissions);
  };

  
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose(); // Refresh parent component
  };

  const handleAccStatusChange = async (event) => {
    const newStatus = event.target.checked ? 1 : 0;
    setAccStatus(newStatus);

    try {
      await axios.post(`${BASE_URL}/auth/activeAcc`, { status: newStatus, userId: UMserId });
    } catch (error) {
      console.error("Error updating account status:", error);
      setAccStatus((prev) => !prev); // Revert change on error
    }
  };

  const handlePermissionChange = (routeName, type, checked) => {
    setPermissions((prev) => ({
      ...prev,
      [routeName]: { ...prev[routeName], [type]: checked },
    }));
  };

  const handleClickSave = async () => {
    try {
      const updatedPermissions = Object.keys(permissions).map((routeName) => ({
        routeName,
        idroutes: recordsPermission.find((perm) => perm.routeName === routeName)?.idroutes,
        View: permissions[routeName].View ? "1" : "0",
        Add: permissions[routeName].Add ? "1" : "0",
        Edit: permissions[routeName].Edit ? "1" : "0",
        Delete: permissions[routeName].Delete ? "1" : "0",
        id: UMserId,
      }));

      await axios.delete(`${BASE_URL}/auth/DeletePermission/${UMserId}`);
      await axios.post(`${BASE_URL}/auth/permission/`, { permissions: updatedPermissions });

      setOpen(false);
      if (onClose) onClose(); // Refresh parent on save
    } catch (err) {
      console.error("Error saving permissions:", err);
    }
  };

  return (
    <div>
      <MDButton type="button" variant="gradient" color="success" onClick={handleClickOpen}>
        Details
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl" onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={7.5}>
              <MDBox mb={0.5}>
                <MDInput label="User" value={username} variant="standard" name="EmpNo" fullWidth disabled />
              </MDBox>
            </Grid>
          </Grid>

          <FormControlLabel
            control={
              <Switch
                checked={accStatus}
                onChange={handleAccStatusChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Active"
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
               
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Route Name</TableCell>
                  <TableCell>View</TableCell>
                  <TableCell>Add</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
                {recordsPermission.map((output, index) => (
                  <TableRow key={index}>
                    <TableCell>{output.idroutes}</TableCell>
                    <TableCell>{output.routeName}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={permissions[output.routeName]?.View || false}
                        onChange={(e) => handlePermissionChange(output.routeName, 'View', e.target.checked)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={permissions[output.routeName]?.Add || false}
                        onChange={(e) => handlePermissionChange(output.routeName, 'Add', e.target.checked)}
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={permissions[output.routeName]?.Edit || false}
                        onChange={(e) => handlePermissionChange(output.routeName, 'Edit', e.target.checked)}
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={permissions[output.routeName]?.Delete || false}
                        onChange={(e) => handlePermissionChange(output.routeName, 'Delete', e.target.checked)}
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClickSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
