import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Divider, Grid } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const EditJobs = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    quotation_no: '',
    job_hrs: '',
    hrs_for_day: '',
    job_status: '',
    start_date: '',
    recode_status: '',
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add one day
    return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/jobs/Jobsbyid/${data.quotation_no}`);
        setFormData({
          ...res.data[0],
          start_date: formatDate(new Date(res.data[0].start_date)),
          complete_date: formatDate(new Date(res.data[0].complete_date || new Date())),
        });
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    if (open) {
      fetchQuotationData();
    }
  }, [data.quotation_no, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const updateService = async (updatedFields) => { 
    try {
      const response = await axios.post(`${BASE_URL}/jobs/EditUpdate`, { ...formData, ...updatedFields });
      console.log(response.data);
      onCustomerUpdated();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    updateService({});
  };

  const handleCancel = () => {
    updateService({ job_status: 3 });
  };

  const handleDelete = () => {
    updateService({ recode_status: 2 });
  };



  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Edit
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField id="standard-basic" variant="standard"
                  label="Quotation"
                  name="quotation_no"
                  value={formData.quotation_no}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField id="standard-basic" variant="standard"
                  label="Total Hrs For Job"
                  name="job_hrs"
                  value={formData.job_hrs}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField id="standard-basic" variant="standard"
                  label="Working Hrs For Day"
                  name="hrs_for_day"
                  value={formData.hrs_for_day}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField id="standard-basic" variant="standard"
                  label="Date"
                  name="start_date"
                  type="date"
                  value={formData.start_date}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <MDButton variant="contained" color="success" onClick={handleUpdate} fullWidth>
                  Update
                </MDButton>
              </Grid>
              <Grid item xs={4}>
                <MDButton variant="contained" color="warning" onClick={handleCancel} fullWidth>
                  Cancel
                </MDButton>
              </Grid>
              <Grid item xs={4}>
                <MDButton variant="contained" color="error" onClick={handleDelete} fullWidth>
                  Delete
                </MDButton>
              </Grid>
            </Grid>
            <Divider />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditJobs;
