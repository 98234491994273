import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, MenuItem, TextField, Button, Typography, Divider, Grid } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const Add = ({ onCustomerAdded }) => {
  const [formData, setFormData] = useState({
    expenses: '',
    cost: 0,
    idexpenses: '',
    date: '',
    userId: 0,
    remarks: '',
  });

  const [newExpense, setNewExpense] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerType, setCustomerType] = useState('new');  
  const [open, setOpen] = useState(false);
  const [newExpenseOpen, setNewExpenseOpen] = useState(false);
  const [type, setType] = useState([]);
  const [isService, setIsService] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const typeResponse = await axios.get(`${BASE_URL}/expenses/AllExpensesName`);
      setType(typeResponse.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addService = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/expenses/SaveExpenses`, formData);
      console.log(response.data);
      onCustomerAdded();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewExpensesOpen = () => {
    setNewExpenseOpen(true);
  };

  const handleNewExpensesClose = () => {
    setNewExpenseOpen(false);
  };

  const handleAddNewExpense = async () => {
    const newExpenseItem = { idexpenses: type.length + 1, expenses: newExpense };
    try {
      await axios.post(`${BASE_URL}/expenses/SaveExpensesName`, newExpenseItem);
      // Fetch the updated list of expenses
      const typeResponse = await axios.get(`${BASE_URL}/expenses/AllExpensesName`);
      setType(typeResponse.data);
      setNewExpense('');
      handleNewExpensesClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleTypeChange = (e) => {
    const type_id = e.target.value;
    const selectedType = type.find(q => q.id === type_id);

    setFormData({
      ...formData,
      idexpenses: type_id,
    });

    setIsService(selectedType && selectedType.name === 'Service');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        New
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleNewExpensesOpen}>
            New Expenses
          </MDButton>

          <Container maxWidth="sm">
            {customerType === 'new' && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField id="standard-basic" variant="standard"
                      label="Expenses"
                      name="expenses"
                      select
                      onChange={handleTypeChange}
                      fullWidth
                      margin="normal"
                      required
                    >
                      <MenuItem value="">
                        <em>Select Expenses</em>
                      </MenuItem>
                      {type.map((q) => (
                        <MenuItem key={q.idexpenses} value={q.idexpenses}>
                          {q.expenses}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField id="standard-basic" variant="standard"
                      label="Cost"
                      name="cost"
                      value={formData.cost}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField id="standard-basic" variant="standard"
                      label="Date"
                      name="date"
                      type="date"
                      value={formData.date}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField id="standard-basic" variant="standard"
                      label="Remarks"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                Add
              </MDButton>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={newExpenseOpen} onClose={handleNewExpensesClose}>
        <DialogContent>
          <TextField id="standard-basic" variant="standard"
            label="New Expense"
            value={newExpense}
            onChange={(e) => setNewExpense(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewExpensesClose}>Cancel</Button>
          <Button onClick={handleAddNewExpense} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Add;
