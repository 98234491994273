import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, MenuItem, Switch, TextField, Button, Typography, Divider, Grid, Checkbox, FormControlLabel } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Units from './units';
const NewItem = ({ onCustomerAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    price:0,
    type_id: '',
    unit_id: '',
    iditem: '',
    isExpired: 0,
    price: '',
  });

 
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerType, setCustomerType] = useState('new');  
  const [open, setOpen] = useState(false);
  const [type, setType] = useState([]);
  const [unit, setUnit] = useState([]);
  const [isService, setIsService] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [typeResponse, unitResponse] = await Promise.all([
        axios.get(`${BASE_URL}/item/AllType`),
        axios.get(`${BASE_URL}/item/AllUnit`)
      ]);

      setType(typeResponse.data);
      setUnit(unitResponse.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 
  const addService = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/item/SaveItem`, formData);
      console.log(response.data);
      onCustomerAdded();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTypeChange = (e) => {
    const type_id = e.target.value;
    const selectedType = type.find(q => q.id === type_id);

    setFormData({
      ...formData,
      type_id: type_id,
    });

    setIsService(selectedType && selectedType.name === 'Service');
  };
  const handleUnitChange = (e) => {
    const unit_no = e.target.value;

    setFormData({
      ...formData,
      unit_id: unit_no,
    });
  };

  const handleChangeisExpire = (event) => {
    const { checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      isExpired: checked ? 1 : 0,
    }));
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }
  const handleUnitsAdded = async () => {
    try {
      const dtypeRes = await axios.get(`${BASE_URL}/common/AllStatus/${'item'}/${'unit_id'}`);
      setUnit(dtypeRes.data.map(item => ({
        id: item.id,
        name: item.name,
      })));
    } catch (err) {
      setError(err.message);
    }
  };
  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        New Item
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            {customerType === 'new' && (
              <>
                <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField id="standard-basic" variant="standard"
                    label="Type"
                    name="Type"
                    select
                    value={formData.type_id}
                    onChange={handleTypeChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Type</em>
                    </MenuItem>
                    {type.map((q) => (
                      <MenuItem key={q.id} value={q.id}>
                       {q.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                
                <Grid item xs={4}>
                  <TextField id="standard-basic" variant="standard"
                    label="Unit"
                    name="Unit"
                    select
                    value={formData.unit_id}
                    onChange={handleUnitChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Unit</em>
                    </MenuItem>
                    {unit.map((q) => (
                      <MenuItem key={q.id} value={q.id}>
                         {q.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                <Units onUnitsAdded={handleUnitsAdded} />
              </Grid>
                <Grid item xs={6}>
                  <TextField id="standard-basic" variant="standard"
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                  
              
          
                  <Grid item xs={6}>
                    <TextField id="standard-basic" variant="standard"
                      label="Price"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!formData.isExpired}
                          onChange={handleChangeisExpire}
                          name="isExpired"
                          color="primary"
                        />
                      }
                      label="Is Expired"
                    />
                  </Grid>
                  </Grid>
              </>
            )}

            <Grid item xs={12}>
              <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                Add
              </MDButton>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewItem;
