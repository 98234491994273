import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormDialog from "layouts/authentication/userDetails";
import { BASE_URL } from "constants";

export default function Users() {
  const [records, setRecord] = useState([]);
  const [refresh, setRefresh] = useState(false); // Triggers data reload

  // Fetch Users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/auth/allUsers`);
        setRecord(res.data);
      } catch (err) {
        console.log("Error fetching users:", err);
      }
    };
    fetchUsers();
  }, [refresh]); // Runs when refresh state changes

  // Function to trigger refresh
  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "idUser", headerName: "UserID", width: 100 },
    { field: "empNo", headerName: "EmpNo", width: 150 },
    { field: "username", headerName: "User Name", width: 150 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "AccStatus", headerName: "Acc Status", width: 150 },
    {
      field: "Details",
      headerName: "Details",
      width: 150,
      renderCell: (cellValues) => (
        <FormDialog data={cellValues.row} onClose={handleRefresh} onOpen={handleRefresh} />
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <h4>Users</h4>
        <DataGrid
          rows={records.map((output, index) => ({
            id: index + 1,
            idUser: output.id,
            empNo: output.empNo,
            username: output.username,
            email: output.email,
            AccStatus: output.accstatus,
            Status: output.status,
          }))}
          columns={columns}
          pageSizeOptions={[10, 20]}
          checkboxSelection
        />
      </div>
    </DashboardLayout>
  );
}
