import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Divider, Grid, Checkbox, FormControlLabel } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const NewCustomer = ({ onCustomerAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    Mobile: 0,
    phone: 0,
    address: '',
    isCompany: 0,
    customerId: '',
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerType, setCustomerType] = useState('new');
  const [registeredCustomers, setRegisteredCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [customersResponse] = await Promise.all([
        axios.get(`${BASE_URL}/customer/Allcustomers`)
      ]);
      setRegisteredCustomers(customersResponse.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked ? 1 : 0, // Convert boolean to 0 or 1
    });
  };

  const validateForm = () => {
    const { name, email, address, Mobile } = formData;
    if (!name || !email || !address || !Mobile) {
      return 'Please fill out all required fields.';
    }
    return '';
  };

  const addService = async () => {
    const validationError = validateForm();
    if (validationError) {
      setFormError(validationError);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/customer/SaveCustomer`, formData);
      console.log(response.data);
      onCustomerAdded();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormError('');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="contained" color="success"  onClick={handleClickOpen} fullWidth>
        New Customer
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <h3>New Customer</h3>
            {formError && (
              <Typography color="error" variant="body2">
                {formError}
              </Typography>
            )}
            {customerType === 'new' && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField id="standard-basic" variant="standard"
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="standard-basic" variant="standard"
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="standard-basic" variant="standard"
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField id="standard-basic" variant="standard"
                    label="Mobile"
                    name="Mobile"
                    type="tel"
                    value={formData.Mobile}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField id="standard-basic" variant="standard"
                    label="Phone"
                    name="phone"
                    type="tel"
                    value={formData.phone}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isCompany === 1}
                        onChange={handleCheckboxChange}
                        name="isCompany"
                      />
                    }
                    label="Company"
                  />
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                Add
              </MDButton>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewCustomer;
