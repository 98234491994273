import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, MenuItem, Typography, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, CircularProgress, Alert
} from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const JobEmpRegistration = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    job_id: '',
    quotation_no: '',
    job_hrs: '',
    hrs_for_day: '',
    job_status: '1',
    start_date: '',
    idemployee: '',
    end_date: '',
  });
  const [quotation, setQuotation] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [newService, setNewService] = useState({ id: '', start_date: '', end_date: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/jobs/Jobsbyid/${data.quotation_no}`);
        const startDate = res.data[0].start_date ? new Date(res.data[0].start_date) : null;
        const completeDate = res.data[0].complete_date ? new Date(res.data[0].complete_date) : new Date();
        setFormData({
          ...res.data[0],
          start_date: startDate ? formatDate(startDate) : '',
          complete_date: formatDate(completeDate),
        });
        const resItems = await axios.get(`${BASE_URL}/jobs/EmpJobsbyid/${data.quotation_no}`);
        setSelectedServices(resItems.data.map(item => ({
          id: item.job_id,
          name: item.name,
          strat_date_time: item.strat_date_time,
          end_date_time: item.end_date_time,
       
        })));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    if (open) {
      fetchQuotationData();
    }
  }, [data.quotation_no, open]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [quotationRes, employeeRes] = await Promise.all([
          axios.get(`${BASE_URL}/jobs/AllOpenJobs`),
          axios.get(`${BASE_URL}/employee/AllEmployee`)
        ]);

        setQuotation(quotationRes.data.map(item => {
          const start_date = item.start_date ? formatDate(new Date(item.start_date)) : '';
          const end_date = item.start_date ? calculateEndDate(start_date, item.job_hrs, item.hrs_for_day) : '';
          return {
            job_id: item.job_id,
            quotation_no: item.quotation_no,
            name: item.name,
            job_hrs: item.job_hrs,
            hrs_for_day: item.hrs_for_day,
            start_date,
            end_date,
          };
        }));

        setEmployees(employeeRes.data.map(item => ({
          id: item.idemployee,
          name: item.name,
        })));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return '';
    }
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().split('T')[0];
  };

  const calculateEndDate = (startDate, jobHours, hoursPerDay) => {
    if (!startDate) {
      return '';
    }
    const date = new Date(startDate);
    const totalDays = Math.ceil(jobHours / hoursPerDay);
    date.setDate(date.getDate() + totalDays);
    return formatDate(date);
  };

  const handleQuotationChange = (e) => {
    const quotation_no = e.target.value;
    const selected = quotation.find(q => q.quotation_no === quotation_no);

    setFormData({
      ...formData,
      quotation_no: quotation_no,
      job_hrs: selected ? selected.job_hrs : '',
      hrs_for_day: selected ? selected.hrs_for_day : '',
      start_date: selected ? selected.start_date : '',
      end_date: selected ? selected.end_date : '',
    });

    setSelectedQuotation(selected);
  };

  const handleEmployeeChange = (e) => {
    const idemployee = e.target.value;
    setNewService({
      ...newService,
      id: idemployee,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addService = () => {
    const service = employees.find(s => s.id === parseInt(newService.id));
    if (service) {
      setSelectedServices([
        ...selectedServices,
        {
          ...service,
          strat_date_time: newService.start_date,
          end_date_time: newService.end_date,
          job_id: newService.id
        },
      ]);
      setNewService({ id: '', start_date: '', end_date: '' });
    }
  };

  const handleServiceChange = (e, field) => {
    setNewService({
      ...newService,
      [field]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Map the selected services to the detailsData array
      const detailsData = selectedServices.map(service => ({
        job_id: formData.idjobs,
        emp_id: service.id,
        item: service.name,
        start_date: new Date(service.strat_date_time).toISOString(),  // Correctly format the start date
        end_date: new Date(service.end_date_time).toISOString(),            // Correctly format the end date
      }));
  
      if (formData.idjobs !== '') {
        // Delete existing job details
        await axios.delete(`${BASE_URL}/jobs/DeleteDetails/${formData.idjobs}`);
        
        // Save new job details
        await axios.post(`${BASE_URL}/jobs/SaveEmpJob`, { detailsData });
      }
  
      // Notify parent component that customer was added
      onCustomerUpdated();
      
      // Close the modal or form
      handleClose();
    } catch (err) {
      // Set the error message to display to the user
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeService = (index) => {
    const updatedServices = selectedServices.filter((_, i) => i !== index);
    setSelectedServices(updatedServices);
  };

  if (loading) {
    return <CircularProgress />;
  }

 

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Employee
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField id="standard-basic" variant="standard"
                    label="Quotation"
                    name="quotation_no"
                    value={formData.quotation_no}
                    onChange={handleQuotationChange}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField id="standard-basic" variant="standard"
                    label="Total Hrs For Job"
                    name="job_hrs"
                    value={formData.job_hrs}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField id="standard-basic" variant="standard"
                    label="Working Hrs For Day"
                    name="hrs_for_day"
                    value={formData.hrs_for_day}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField id="standard-basic" variant="standard"
                    label="Start Date"
                    name="start_date"
                    type="date"
                    value={formData.start_date}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField id="standard-basic" variant="standard"
                    label="End Date"
                    name="end_date"
                    type="date"
                    value={calculateEndDate(formData.start_date, formData.job_hrs, formData.hrs_for_day)}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="standard-basic" variant="standard"
                    label="Employee"
                    name="idemployee"
                    select
                    value={newService.id}
                    onChange={handleEmployeeChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Employee</em>
                    </MenuItem>
                    {employees.map((emp) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="standard-basic" variant="standard"
                    label="Start Date"
                    name="start_date"
                    type="date"
                    value={newService.start_date}
                    onChange={(e) => handleServiceChange(e, 'start_date')}
                    fullWidth
                    margin="normal"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="standard-basic" variant="standard"
                    label="End Date"
                    name="end_date"
                    type="date"
                    value={newService.end_date}
                    onChange={(e) => handleServiceChange(e, 'end_date')}
                    fullWidth
                    margin="normal"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                    Add
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Selected Services
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                       
                      </TableHead>
                      <TableBody>
                      <TableRow>
                          <TableCell>Employee</TableCell>
                          <TableCell align="right">Start Date</TableCell>
                          <TableCell align="right">End Date</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                        {selectedServices.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {service.name}
                            </TableCell>
                            <TableCell align="right">{new Date(service.strat_date_time).toLocaleDateString()}</TableCell>
                            <TableCell align="right">{new Date(service.end_date_time).toLocaleDateString()}</TableCell>
                            <TableCell align="right">
                              <Button variant="contained" color="success" onClick={() => removeService(index)}>
                                X
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <MDButton type="submit" variant="contained" onClick={handleSubmit} color="success" fullWidth>
                    Save Employee
                  </MDButton>
                </Grid>
              </Grid>
            </form>
            <Divider />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobEmpRegistration;
