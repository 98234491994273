import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Divider, Grid, MenuItem } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const Units = ({ onUnitsAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    id: '',
    table: 'item',
    column: 'unit_id'
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dtypeRes = await axios.get(`${BASE_URL}/common/AllStatus/${'item'}/${'unit_id'}`);
        setFormData(prevFormData => ({
          ...prevFormData,
          designation: dtypeRes.data.map(item => ({
            id: item.id,
            name: item.name,
          }))
        }));

        const idRes = await axios.get(`${BASE_URL}/common/MaxStatusId/${'item'}/${'unit_id'}`);
        const lastid = idRes.data[0].id;
        const nextid = String(parseInt(lastid) + 1);
        setFormData(prevFormData => ({
          ...prevFormData,
          id: nextid,
        }));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { name } = formData;
    if (!name) {
      return 'Please fill out all required fields.';
    }
    return '';
  };

  const addService = async () => {
    const validationError = validateForm();
    if (validationError) {
      setFormError(validationError);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/common/SaveStatus`, formData);
      console.log(response.data);
      onUnitsAdded();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormError('');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="small" onClick={handleClickOpen}>
        New Unit
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <h3>New Unit</h3>
            {formError && (
              <Typography color="error" variant="body2">
                {formError}
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField id="standard-basic" variant="standard"
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <MDButton variant="contained" color="success" size="small" onClick={addService} fullWidth>
                  Add
                </MDButton>
              </Grid>
            </Grid>
            <Divider />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Units;
